@import '../../style/variables.scss';

.issues__card--wrapper {
    margin-top: 15px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
    transition: all 0.2s;
    background-color: $card-background-color;
    .issuer__card--container {
        width: 100%;
        height: 100%;
        .issues__card--main {
            position: relative;
            min-height: 50px;
            display: flex;
            align-items: center;
            .issues__card--icon {
                margin: 14px;
                width: 25px !important;
                height: 25px !important;
                @media screen and (max-width: 740px) {
                    margin: 10px;
                    width: 20px !important;
                    height: 20px !important;
                }
            }
            .issues__card--name {
                font-weight: 700;
                font-size: 16px;
                padding: 0;
                margin: 0 15px 0 20px;
                @media screen and (max-width: 740px) {
                    font-size: 16px;
                    margin-left: 5px;
                }
                @media screen and (max-width: 520px) {
                    font-size: 13px;
                    margin-right: 40px;
                    font-weight: 500;
                }
            }
            .issues__card--info {
                font-size: 16px;
                color: $main-text-color;
                margin: 0 100px 0 auto;
                @media screen and (max-width: 740px) {
                    font-size: 14px;
                    margin-right: 50px;
                }
                @media screen and (max-width: 520px) {
                    display: none;
                }
            }
            .arrow__more--container {
                border-radius: 0 6px 6px 0;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                background-color: $issues-arrow-container;
                height: 100%;
                .arrow__icon {
                    width: 40px;
                    transition: all 0.1s linear;
                    @media screen and (max-width: 740px) {
                        width: 20px;
                    }
                }
                .arrow__icon-active {
                    transform: rotateX(180deg);
                }
                @media screen and (max-width: 740px) {
                    width: 40px;
                }
            }
            &:hover {
                cursor: pointer;
                @media screen and (max-width: 740px) {
                    cursor: default;
                }
            }
            @media screen and (max-width: 740px) {
                min-height: 50px;
            }
        }
        .issues__card--content {
            padding: 20px 40px;
            border-top: 1px solid rgba(0, 0, 0, 0.137);
            background-color: $card-background-color;
            border-radius: 0 0 6px 6px;
            .audit__desc {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .audit__text {
                    max-width: 80%;
                    margin-right: 20px;
                    color: rgba(0, 0, 0, 0.699);
                    font-size: 16px;
                    line-height: 20px;
                    @media screen and (max-width: 520px) {
                        font-weight: 500;
                        font-size: 13px;
                        max-width: none;
                        margin-bottom: 10px;
                    }
                }
                a {
                    font-weight: 400;
                    font-size: 14px;
                    max-height: 30px;
                    @media screen and (max-width: 740px) {
                        margin: 0 auto;
                    }
                    @media screen and (max-width: 520px) {
                        font-size: 13px;
                    }
                }
            }
            .audit__content {
                margin-top: 10px;
                .audit__text {
                    max-width: 80%;
                    margin-right: 20px;
                    color: rgba(0, 0, 0, 0.699);
                    font-size: 16px;
                    line-height: 20px;
                    @media screen and (max-width: 520px) {
                        font-size: 13px;
                    }
                }
                .audit__table {
                    width: 100%;
                    max-width: 100%;
                    .table__head-name {
                        text-transform: uppercase;
                        width: 100%;
                    }
                    .table__head-influence {
                        text-transform: uppercase;
                    }
                    thead {
                        @media screen and (max-width: 740px) {
                            font-size: 13px;
                        }
                    }
                    tbody {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.562);
                        .table__head-item {
                            color: rgba(112, 60, 0, 0.616);
                            padding: 2px 30px 5px 10px;
                            position: relative;
                            overflow: hidden;
                            text-wrap: wrap;
                            word-break: break-all;
                            &::before {
                                content: '';
                                top: 50%;
                                transform: translateY(-50%);
                                position: absolute;
                                left: 0;
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background-color: rgba(0, 0, 0, 0.384);
                            }
                        }
                        .table__head-performance {
                            word-break: normal;
                        }
                        @media screen and (max-width: 740px) {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
    &:hover {
        transform: translateY(-1px);
        @media screen and (max-width: 740px) {
            transform: none;
        }
    }
    @media screen and (max-width: 740px) {
        margin-top: 10px;
    }
}