@import '../../style/variables.scss';

.multi-color-progress-bar {
    width: 100%;
    height: 4px;
    background-color: $card-background-color;
    border-radius: 4px;
    position: relative;
    .progress {
        height: 100%;
        background-size: 300% 100%;
        animation: fill 3s linear infinite;
        .progress-indicator {
            position: absolute;
            z-index: 1;
            top: -4px;
            bottom: -12px;
            width: 2px;
            background-color: rgb(0, 0, 0); 
            transition: all 0.7s ease-in-out;
            content: '';
            .progress-indicator-metrix {
                font-size: 10px;
                top: 14px;
                left: 5px;
                position: absolute;
            }
        }
    } 
}

@keyframes fill {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -200% 0;
    }
}