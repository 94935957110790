@import '../../style/variables.scss';

.issues__title {
    color: $main-color;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    @media screen and (max-width: 740px) {
        font-size: 28px;
    }
    @media screen and (max-width: 620px) {
        display: none;
    }
}


.c-wrapper {
    .issues__container {
        .issues__cards--container {
            margin-top: 30px;
            @media screen and (max-width: 740px) {
                margin-top: 0;
            }
        }
    }
    @media screen and (max-width: 640px) {
        padding-top: 50px;
        margin-top: 85px;
    }
}

.not__info--issues {
    padding: 0;
    margin-top: -10px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: rgb(165, 0, 0);
    text-transform: uppercase;
    @media screen and (max-width: 740px) {
        font-size: 18px;
        margin-top: 20px;
    }
}
