@import '../../style/variables.scss';

.error__msg {
    position: absolute;
    right: 10px;
    top: 70px;
    z-index: 200;
    background-color: $error-card;
    color: $white-color;
    max-width: 200px;
    padding: 10px 20px;
    border-radius: 5px;
    animation: fadeOut 5.1s ease-in-out;
    .msg__text {
        margin: 0;
    }
}


@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
  }