@import '../../style/variables.scss';

.vitals__card {
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    flex: 1;
    box-sizing: border-box;
    min-width: 0;
    .vitals__card--title {
        font-weight: 700;
        font-size: 13px;
        color: #596679; 
        text-wrap: nowrap;
        overflow: hidden;
        @media screen and (max-width: 980px) {
            display: none;
        }
    }
    .vitals__card--name {
        color: $main-color;
        font-weight: 900;
        margin-top: 15px;
        line-height: 48px;
        font-size: 42px;
        letter-spacing: -0.005em;
    }
    .vitals__card--full-name {
        margin-bottom: 10px;
    }
    @media screen and (max-width: 980px) {
        position: relative;
        padding-bottom: 35px !important;
        align-items: center;
        width: 70%;
    }
}




////
.multi-color-progress-bar {
    width: 100%;
    margin-top: auto;
    margin-bottom: 45px;
}