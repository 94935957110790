@import '../../style/variables.scss';


.keywords-card--wrapper {
    flex: 1;
    min-width: 500px;
    position: relative;
    .keywords-card--header {
        display: flex;
        column-gap: 5px;
        .keywords-title {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            color: $main-color;
        }
        .keywords-icon {
            img {
                width: 20px;
                opacity: 0.7;
            }
            position: relative;
            &:hover .keywords-icon__msg {
                display: block;
            }
            .keywords-icon__msg {
                line-height: 20px !important;
                opacity: 0.9;
                font-size: 13px;
                border-radius: 5px;
                z-index: 3;
                width: 250px;
                font-weight: 500 !important;
                padding: 10px;
                color: white !important;
                position: absolute;
                display: none;
                background-color: rgb(0, 86, 167);
                top: 30px;
                left: 0px;
                animation: scaleUp 0.1s;
            }
        }
    }
    .keywords--container {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .keywords-card--container {
        position: relative;
        height: 280px;
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        background-color: $card-background-color;
        box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
        border-radius: 6px;
        min-width: 40%;
        align-items: center;
        padding: 10px 0px;
        .keywords-content--header {
            width: 100%;
            display: flex;
            justify-content: space-around;
            .keywords-content--item {
                display: flex;
                flex-direction: column;
                align-items: center;
                * {
                    margin: 0;
                }
                .keywords-content--title {
                    font-size: 13px;
                    font-weight: 400;
                    color: $main-text-color;
                    @media screen and (max-width: 560px) {
                        font-size: 11px;   
                    }
                }
                .keywords-content--value {
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: $main-color;
                }
            }
        }
        .show-traff--button {
            font-weight: 400;
            margin: 10px 15px 0 15px;
            max-width: 200px;
        }
        .keywords-content--table {
            width: 100%;
            margin-top: 10px;
            border-top: 1px solid rgb(213, 213, 213);
            border-bottom: 1px solid rgb(213, 213, 213);

            td:not(:last-child), th:not(:last-child) {
                border-right: 1px solid rgb(213, 213, 213);
            }
            th {
                font-weight: 500;
            }
            td, th  {
                text-align: right;
                padding: 2px 20px;
                @media screen and (max-width: 500px) {
                    padding: 5px;
                }
            }
            td:first-child, th:first-child {
                padding-left: 30px;
                @media screen and (max-width: 500px) {
                    padding-left: 5px;
                }
            }
            td:last-child, th:last-child {
                padding-right: 30px;
                @media screen and (max-width: 500px) {
                    padding-right: 5px;
                }
            }
            column-gap: 60px;
        }
        .not_data_icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 50%;
            opacity: 0.4;
            transform: translate(-50%, -50%);
            font-size: 22px;
            img {
                width: 60px;
            }
        }
    
    }
    @media screen and (max-width: 540px) {
        min-width: 250px;
    }

}