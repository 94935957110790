@import '../../style/variables.scss';

.running-bar-container {
    width: 70%;
    height: 4px; 
    background-color: #ccc; 
    overflow: hidden;
    margin: -15px 0 40px 0;
    .running-bar {
      width: 100%;
      height: 100%;
      background-color: $main-color;;
      animation: running-bar-animation 2s linear infinite; 
    }
  }
  
  @keyframes running-bar-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  