@import '../../style/variables.scss';

.consultation-popup--wrapper {
    position: fixed;
    right: 150px;
    bottom: -70px;
    padding: 10px 20px;
    z-index: 119;
    background-color: white;
    height: 120px;
    min-width: 100px;
    box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);    
    border-radius: 6px; 
    transition: all 0.2s;
    text-align: center;
    border: 1px solid rgb(240, 240, 240);
    .consultation-popup--container {
        .consultation-popup--text {
            color: black;
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 15px;
            @media screen and (max-width: 650px) {
                font-size: 13px;
            }
        }
        button {
            background-color: red;
            border-color: red;
            @media screen and (max-width: 650px) {
                font-size: 13px;
            }
            &:active {
                background-color: red;
                border-color: red;
                transform: translateY(0.3px);
                @media screen and (max-width: 720px) {
                    transform: none;
                }
            }
        }
    }
    &:hover {
        transform: translateY(-50px);
        animation: none !important;
    }
    @media screen and (max-width: 650px) {
        bottom: -80px;
        right: 10%;
    }
};

.bounce-container {
    animation: bounce 1s ease;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-10px);
    }
}