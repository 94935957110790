@import '../../style/variables.scss';

.report--seo-score {
    padding-bottom: 30px;
    .report__data--container {
        .report--seo-score--title {
            margin-bottom: 20px;
            text-align: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
        }
        .circular-progress-bar {
            max-width: 150px;
        }
        .report__seo--percentage-info {
            height: 35px;
            width: 50%;
            display: grid;
            grid-template-columns: repeat(2, auto);
            justify-content: space-around;
        }
        .report--seo-score--desc {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-height: 50px;
            text-align: center;
            margin-top: auto;
            .skeleton--blck {
                align-items: center;
            }
            .skeleton-item2 {
                width: 40%;
            }
            .skeleton-item1 {
                width: 60%;
            }

        }

    }
}





.indicator {
    text-wrap: nowrap;
    position: relative;
    font-size: 10px;
}

.indicator::before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 8px;
    margin: 0 10px;
    background-color: red;
}

.green__indicator::before {
    background-color: green;
}

.yellow__indicator::before {
    background-color: rgb(214, 214, 0);
}

.orange__indicator::before {
    background-color: orange;
}





