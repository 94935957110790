@import '../../style/variables.scss';


.report__metrix {
    .report__metrix--head {
        margin-top: 60px;
        display: flex;
        padding: 15px 0;
        @media screen and (max-width: 800px) {
            margin-top: 20px;
        }
        @media screen and (max-width: 500px) {
            flex-direction: column;
            gap: 5px;
        }
        .report__metrix--title {
            font-weight: 700;
            line-height: 24px;
            font-size: 18px;
            margin: 0 10px;
            @media screen and (max-width: 800px) {
                font-size: 16px;
            }
        }
    }
    .report__metrix-card--wrapper {
        background-color: $card-background-color;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
        width: 100%;
        .report__metrix--core-head {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            margin: 30px;
            display: flex;
            column-gap: 10px;
        }
        .reprort__metrix--vitals {
            min-height: 200px;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -webkit-box-align: stretch;
            align-items: stretch;
            display: flex;
            -webkit-box-pack: start;
            justify-content: flex-start;
            flex-direction: row;
            .report__metrix--vitals-graphs { 
                display: flex;
                -webkit-box-flex: 1;
                flex: 1 1 100%;
                max-width: 60%;
                max-height: 100%;
                box-sizing: border-box;
                @media screen and (max-width: 980px) {
                    flex-direction: column;
                    align-items: center;
                    max-width: 100%;
                }
            }
            .separator-container {
                display: flex;
                align-items: center;
                min-height: 60%;
                @media screen and (max-width: 980px) {
                    display: none;
                }
                .separator {
                    height: 70%;
                    background-color: #0000003d;
                    width: 1px;
                }
            }
            .google-report {
                max-width: 400px;
                display: flex;
                flex-direction: column;
                justify-content: center; 
                margin-right: 30px;
                margin-left: 30px;
                @media screen and (max-width: 980px) {
                    display: none;
                }
                .google-report--title {
                    font-weight: 700;
                    color: #130D41;
                    display: flex;
                    column-gap: 10px;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.010em;
                }
                .google-report--desc {
                    letter-spacing: 0.020em;
                    font-size: 13px;
                    margin-top: 10px;
                }
            }
        }
        .report__metrix--time-analys {
            background-color: $card-background-color;
            padding: 0 15px;
            max-width: 100%;
            max-height: 100%;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.010em;
            line-height: 24px;
            display: grid;
            grid-template-columns: repeat(2, 50%);
            @media screen and (max-width: 960px) {
                display: block;
                .time__analys--element:not(:nth-last-child(-n+1)) {
                    border-bottom: 1px solid rgb(238, 238, 238);
                }
            }
            .time__analys--element:not(:nth-last-child(-n+2)) {
                border-bottom: 1px solid rgb(238, 238, 238);
            }
            .time__analys--element {
                align-items: center;
                display: flex;
                height: 53px;
                min-height: 0;
                width: 100%;
                margin-right: 15px !important;
                padding: 15px 10px;
                @media screen and (max-width: 500px) {
                    font-style: 13px;
                }
                .info__icon {
                    position: relative;
                    &:hover .info__icon--msg {
                        display: block;
                    }
                    .info__icon--msg {
                        line-height: 20px !important;
                        opacity: 0.9;
                        font-size: 13px;
                        border-radius: 5px;
                        width: 250px;
                        font-weight: 500 !important;
                        padding: 10px;
                        color: white !important;
                        position: absolute;
                        display: none;
                        background-color: rgb(0, 86, 167);
                        top: -30px;
                        left: 30px;
                        animation: scaleUp 0.1s;
                    }
                }
                .time__analys--info {
                    margin-left: auto;
                    align-items: center;
                    display: flex;
                    .time__analys--color-icon {
                        height: 18px;
                        width: 18px;
                        margin-left: 10px;
                    }
                }
                p {
                    margin: 0 10px;
                    color: #130D41;
                    font-weight: 700;
                    letter-spacing: 0.010em;
                    line-height: 24px;
                }
            }
        }
        
    }
}

    





