@import '../../style/variables.scss';

.serpstat-cards {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.serpstat-modules {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 40px;
}

.serpstat-history--charts-container {
    width: 100%;
}
.report__web-text {
    margin-right: 0 !important;
}


.country-selector--container {
    padding-left: 10px;
    width: 200px;
    font-size: 13px;
    margin-left: auto;
    font-weight: 500;
    color: gray;
    @media screen and (max-width: 500px) {
        width: 120px;
        min-width: 120px;
        font-size: 11px;
        font-weight: 400;
    }
}

.serpstat__header {
    .report__main-info {
        flex: none;
    }
}