
@import '../../style/variables.scss';

.c-wrapper {    
    &.blur {
        filter: blur(3px);
    }
    .report-container {
        display: block; 
        position: relative;
        min-height: 0;
        @media screen and (max-width: 960px) {
            flex-wrap: wrap;
        }
    }
    @media screen and (max-width: 640px) {
        padding-top: 50px;
        margin-top: 95px;
    }
}

.main__spinner--container  {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 440px) {
        row-gap: 0;
    }
    .spinner-gif {
        width: 200px;
        height: auto;
        @media screen and (max-width: 780px) {
            width: 150px;
        }
        @media screen and (max-width: 580px) {
            width: 100px;
        }
    }
    .loading-info {
        color: $main-text-color;
        font-weight: 500;
        font-size: 24px;
        text-align: center;
        @media screen and (max-width: 780px) {
            font-size: 18px;
        }
        @media screen and (max-width: 580px) {
            font-size: 13px;
        }
    }
}



//unused
.report__row--data {
    margin-top: 30px;
}

.report__main-info {
    flex: 1;
    align-items: center;
    -webkit-box-flex: 1;
}

.desktop-version {
    width: 1700px !important;
}

.for-pdf {
    all: initial !important;
}