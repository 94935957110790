@import '../../style/variables.scss';

.distrib-container {
    .css-7uzo9y-MuiSkeleton-root {
        width: 80% !important;
        margin: 0 auto;
        min-height: 250px !important;
    }
    height: 280px;
}
