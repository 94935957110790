@import '../../style/variables.scss';



.close-button {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 121;
    font-weight: 500;
    font-size: 13px;
    margin-left: auto;
    background-color: transparent;
    border: none;
    img {
        width: 15px;
        opacity: 0.5;
        height: auto;
    }  
}


.price-popup--container {
    padding: 20px 40px;
    position: fixed;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    z-index: 121;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;
    opacity: 0;
    transition: all .2s ease;
    @media screen and (max-width: 700px) {
        width: 100%;
        position: absolute;
    }   
    .cards-container {
        display: flex;
        column-gap: 20px;
        position: relative;
        margin-bottom: 10px;
        flex-direction: row;
        row-gap: 20px;
        .price-card {
            max-width: 700px;
            min-height: 200px;
            padding: 20px 30px 30px 30px    ;
            min-width: 500px;
            background-color: white;
            box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
            border-radius: 6px;
            transition: all .3s ease;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 600px) {
                min-height: 0;
            }
            .prices-card--text {
                font-size: 28px;
                font-weight: 500;
                line-height: 34px;
                margin-bottom: 40px;
                text-align: center;
                @media screen and (max-width: 600px) {
                    font-size: 20px;
                    line-height: 22px;
                    margin-bottom: 15px;
                }
                @media screen and (max-width: 500px) {
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom: 15px;
                }
            }
            .input-form {
                margin-top: auto;
                margin-top: auto;
                display: flex;
                width: auto;
                @media screen and (max-width: 500px) {
                    flex-direction: column;
                }
                select {
                    width: 100px;
                }
                button {
                    min-width: 80px;
                    border-radius: 0 6px 6px 0;
                    @media screen and (max-width: 500px) {
                        border-radius: 0 0 6px 6px;
                        font-size: 11px;
                    }
                }
                .spinner-border{
                    width: 20px;
                    height: 20px;
                }
                .react-tel-input {
                    width: auto;
                    .form-control {
                        border-radius: 6px 0 0 6px;
                        &:focus {
                            box-shadow: none;
                        }
                        @media screen and (max-width: 500px) {
                            border-radius: 6px 6px 0 0;
                            font-size: 11px;
                        }
                        @media screen and (max-width: 600px) {
                            max-width: 200px !important;
                            min-width: 0;
                        }
                    }
                }
                .PhoneInputCountry {
                    img {
                        width: 20px;
                    }
                }
                

            }
            .not-valid--phone {
                color: red;
                font-size: 13px;
                text-align: center;
                font-weight: 500;
                //  
            }
            @media screen and (max-width: 600px) {
                min-width: 0;
                padding: 10px 20px;
            }

            @media screen and (max-width: 1000px) {
                width: 200px;
            }
            @media screen and (max-width: 700px) {
                width: 100%;
            }
        }
        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
        
    }
}

.prices-popup--background {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 120;
    background-color: black;
    opacity: 0.5;
}

.active {
    opacity: 1;
    top: 20%;
}