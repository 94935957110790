@import '../../style/variables.scss';

.change-device-btn {
    border-radius: 20px;
    font-size: 15px;
    max-height: 16px;
    width: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $card-background-color;
    color: $main-text-color;
    border: none;
    column-gap: 10px;
    padding: 15px;
    box-shadow: 0px 4px 8px rgba(14,30,62,0.12);
    .change-device-btn--elem {
        display: flex;
        max-height: 16px;
        align-items: center;
    }
    .change-device-btn--icon {
        position: relative;
        width: 16px;
        height: 16px;
        margin: 0;
        margin-right: 5px;
        fill: $card-background-color;
    }
    p {
        margin: 0;
    }
    &.change-device-btn--mobile {
        &::before {
            left: 100px;
        }
    }
    &::before {
        position: absolute;
        content: "";
        display: block;
        left: 0;
        width: 50%;
        height: 30px;
        background-color: $change-device-btn-bfr;
        border-radius: 20px;
        transition: left 0.15s ease-in;
    }
}
