@import './variables.scss';

.App {
    min-height: 100vh;
    height: auto;
    display: flex;
    color: #4f4f4f;
}


//skelton
.css-7uzo9y-MuiSkeleton-root {
    width: 12%;
}

.xAxis, .yAxis {
    font-size: 13px;
    color: $main-text-color;
}

.recharts-tooltip-cursor {
    display: none !important;
}




.c-wrapper {
    margin-top: 65px;
    // margin-left: 100px;
    padding: 70px 70px 30px 70px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;
    @media screen and (max-width: 680px) {
        padding: 70px 20px 30px 20px;
    }
}

.c-container {
    max-width: 2000px;
    margin: 0 auto;
    width: 100%;
}



.circular-progress-bar {
    max-width: 65%;
}

.circular-progress-bar tspan {
    display: none;
}

.percentage25 svg :first-child {
    fill: rgba(255, 0, 0, 0.1);
}

.percentage25 tspan {
    fill: black;
}

.percentage50 svg :first-child {
    fill: rgba(255, 166, 0, 0.1);
}

.percentage75 svg :first-child {
    fill: rgba(255, 255, 0, 0.1);
}

.percentage100 svg :first-child {
    fill: rgba(0, 196, 0, 0.1);
}

.captcha__container {
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}

.fixed-width {
    width: 1200px !important;
}


.fixed-width
.info__icon,
.fixed-width 
.report__analysis, 
.fixed-width 
.report__metrix--head,
.fixed-width 
.separator-container,
.fixed-width 
.google-report,
.fixed-width 
.change-device-btn,
.fixed-width
.report__preview,
.fixed-width
.report__metrix
.report__metrix--head {
    display: none !important;
}

.fixed-width
.report__data--content {
    flex-wrap: none;
}

.fixed-width
.report__metrix--vitals-graphs {
    flex-direction: row !important;
}

.hide {
    display: none;
}

@media screen and (max-width: 480px) {
    .captcha__container {
        display: none;
    }
}