@import '../../../style/variables.scss';

.history-chart--wrapper {
    margin-top: 30px;
    width: 100%;
    .history-card--header {
        display: flex;
        column-gap: 5px;
        .history-title {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            color: $main-color;
        }
        .history-icon {
            img {
                width: 20px;
                opacity: 0.7;
            }
            position: relative;
            &:hover .history-icon__msg {
                display: block;
            }
            .history-icon__msg {
                line-height: 20px !important;
                opacity: 0.9;
                font-size: 13px;
                border-radius: 5px;
                z-index: 3;
                width: 250px;
                font-weight: 500 !important;
                padding: 10px;
                color: white !important;
                position: absolute;
                display: none;
                background-color: rgb(0, 86, 167);
                top: 30px;
                left: 0px;
                animation: scaleUp 0.1s;
            }
        }
    }
    .history-chart--container {
        min-height: 225px;
        position: relative;
        margin-top: 8px;
        background-color: $card-background-color;
        width: 100%;
        box-shadow: $card-shadow;
        border-radius: $card-border-radius;
        padding: 10px;
        .ext-points {
            display: flex;
            justify-content: space-around;
            .point {
                text-align: center;
                .point--value {
                    color: $main-color;
                }
                .point--title {
                    color: $main-text-color;
                }
                * {
                    margin: 0;
                }
            }
        }
        .css-7uzo9y-MuiSkeleton-root {
            height: 225px;
            width: 100%;
        }
    }
    .no_data_icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0.4;
        transform: translate(-50%, -50%);
        font-size: 22px;
        img {
            width: 60px;
        }
    }
}