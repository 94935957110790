@import '../../style/variables.scss';


.radar__percentage {
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0 20px;
    .radar__percentage--text {
        margin: 0;
        padding: 0;
        font-size: 13px;
    }
    span {
        margin: 0;
        padding: 0;
        font-size: 11px;
        font-weight: 700;.radar__percentage p{
            margin: 0;
            padding: 0;
            font-size: 13px;
        }
        
        .radar__percentage span{
            margin: 0;
            padding: 0;
            font-size: 11px;
            font-weight: 700;
        }
        
        .radar__percentage {
            margin: 0 auto;
            width: 90%;
            display: grid;
            grid-template-columns: repeat(2, auto);
            gap: 0 20px;
            color: rgb(235, 235, 235);
        }
    }
}