@import '../../style/variables.scss';


.backlinks-card--wrapper {
    flex: 1;
    min-width: 100%;
    .backlinks-card--header {
        display: flex;
        column-gap: 5px;
        .backlinks-title {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            color: $main-color;
        }
        
    }
    .backlinks-card--container {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;    
        border-radius: 6px;
        min-height: 140px;
        row-gap: 20px;
        box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
        @media screen and (max-width: 650px) {
                flex-direction: column;
        }
        .backlinks-item--container:not(:last-child) {
            border-right: 1px solid rgb(213, 213, 213);
            @media screen and (max-width: 650px) {
                border-bottom: 1px solid rgb(213, 213, 213);
                border-right: none;
            }
        }
        .backlinks-item--container {
            min-width: 260px;
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 6px 18px;
            @media screen and (max-width: 1200px) {
                width: 50%;
                flex: none;
            }
            @media screen and (max-width: 650px) {
                width: 100%;
        }
            
        }
        .sdr {
            flex-direction: column;
            .sdr-data {
                display: flex;
                font-size: 26px;
                .sdr-title {
                    font-weight: 700;
                    color: $main-text-color;
                    margin-bottom: 0;
                }
                .sdr-progress {
                    text-align: right;
                    font-weight: 500;
                    color: $main-color;
                    margin-left: auto;
                    margin-bottom: 0;
                }
            }
            .sdr-description {
                color: $main-text-color;
                margin-top: auto;
                display: flex;
                line-height: 16px;
                font-size: 14px;
            }
        }
        .backlinks-item--row {
            color: $main-text-color;
            width: 100%;
            display: flex;
            margin-bottom: auto;
            .row__value {
                color: $main-color;
                font-weight: 500;
                display: flex;
                margin-left: 40px;
            }
            .row__title {
                display: flex;
                gap: 5px;
                margin-bottom: 15px;
                width: 65%;
                .row__title--text {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .row__value--change {
                font-size: 12px;
            }
        }
        .backlinks-item--subrow {
            color: $main-text-color;
            width: 100%;
            display: flex;
            opacity: 0.9;
            .row__value {
                color: $main-color;
                font-weight: 500;
                display: flex;
                margin-left: 40px;
                font-size: 13px;
            }
            .row__title {
                width: 65%;
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 500;
            }
            .row__value--change {
                font-size: 12px;
            }
        }
    }
}



.no-data {
    color: gray;
    font-size: 14px;
}

.backlinks-icon {
    img {
        width: 20px;
        opacity: 0.7;
    }
    position: relative;
    &:hover .backlinks-icon__msg {
        display: block;
    }
    .backlinks-icon__msg {
        line-height: 20px !important;
        opacity: 0.9;
        font-size: 13px;
        border-radius: 5px;
        z-index: 3;
        width: 250px;
        font-weight: 500 !important;
        padding: 10px;
        color: white !important;
        position: absolute;
        display: none;
        background-color: rgb(0, 86, 167);
        top: 30px;
        left: 0px;
        animation: scaleUp 0.1s;
    }
}