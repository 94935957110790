.skeleton__icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: #f0f0f0;
    margin-right: 20px;
    animation: loading 1.5s infinite;
    border-radius: 4px;
  }
  
  .skeleton__icon--block {
    margin: 5px 0;
    background: #e0e0e0;
    border-radius: 4px;
  }
  
  @keyframes loading {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  