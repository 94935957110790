@import '../../style/variables.scss';

.page-not-found--wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .page-not-found--container {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        .page-not-found--code {
            font-size: 160px;
            line-height: 160px;
            margin: 0;
            @media screen and (max-width: 780px) {
                font-size: 120px;
                line-height: 120px;
            }
        }
        .page-not-found--msg {
            font-size: 40px;
            font-weight: 400;
            line-height: 40px;
            margin: 0;
            @media screen and (max-width: 780px) {
                font-size: 30px;
                line-height: 30px;
            }
        }
    }
}