@import '../../style/variables.scss';

.report__data--content {
    margin-top: 30px;
    display: flex;
    gap: 30px;
    .report__data--wrapper {
        background-color: $card-background-color;
        padding: 30px 20px 20px 20px;
        height: 417px;
        min-width: 350px;
        border-radius: 6px;
        flex: 1;
        box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
        .report__data--container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 20px;
            align-items: center;
            height: 100%;
            align-items: center;
            img {
                box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
                border-radius: 5px;
                height: auto;
                max-width: 100%;
            }
        }
        @media screen and (max-width: 680px) {
            height: auto;
            min-width: 0;
        }
    }
    @media screen and (max-width: 960px) {
        flex: 1;
    }
    @media screen and (max-width: 680px) {
        margin-top: 15px;
        gap: 20px;
    }
}




//? 

@media screen and (max-width: 1200px) {
    .report__analysis {
        display: none;
    }
}

@media screen and (max-width: 960px) {
    .report__data--content {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 680px) {
    .report-container {
        width: 100%
    }
    .report__data--content {
        margin-top: 15px;
        gap: 20px;
    }
}