
.spinner {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;    
    .loading-info {
        text-align: center;
        margin-top: 10px;
    }
}

.admin-header {
    position: fixed;
    z-index: 20;
    padding: 20px 40px;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0 6px 30px #0e1e3e14;
    font-size: 16px;    
    button {
        margin-right: 20px;
        background-color: transparent;
        border: none;
    }
    .active-page {
        color: #0d6efd;
    }
}


.success-popup--wrapper {
    position: fixed;
    z-index: 200;
    top: 60px;
    right: 60px;
    padding: 10px 30px;
    color: white;
    background-color: rgb(58, 181, 38);
    opacity: 1;
    border-radius: 6px;
    transition: all 0.8s ease;
    .success-popup--text {
        padding: 0;
        margin: 0;
    }
}

.success-popup--active {
    opacity: 0;
}

.rdrDefinedRangesWrapper {
    display: none;
}

.admin-wrapper {
    display: block;
    padding: 70px 50px;
    width: 100%;
    padding-top: 100px; 
    .page-header {
        margin-top: -20px;
        .page-info {
            display: flex;
            column-gap: 20px;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            margin-bottom: 10px;
            align-items: center;
            .page-info--url {
                text-decoration: none;
            }
            .page-info--name {
                margin: 0;
            }
            .page-info--icon {
                width: 24px;
                height: 24px;
                padding-top: 2px;
                margin-left: -15px;
            }
        }
    }
    .form-request-limit {
        margin-bottom: 20px;
        width: 400px !important;
        #points {
            width: 80%;
            color: rgb(91, 91, 91);
        }
        color: rgb(32, 32, 32)
    }
    
    .keyword-table {
        width: 100%;
        margin-bottom: 40px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        tr {
            height: 28px;
        }
        tr td, th{
            padding: 2px 20px;
        }   
        td:hover {
            cursor: pointer;
        }
        td:not(:first-child), th:not(:first-child) {
            border-left: 1px solid rgb(213, 213, 213);;
        }
        .keyword-table--header {
            border-radius: 50%;
            background-color: gray;
            color: white;
        }
    }
    
}

.blur {
    filter: blur(5px);
}

.calendar__wrapper {
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    img {
        margin: 3px;
        margin-left: auto;
        width: 15px;
        opacity: 0.5;
        &:hover {
            cursor: pointer;
        }
    }
    .calendar-container {
        border: 0.5px solid rgb(216, 216, 216);
        box-shadow: 0 6px 30px rgba(14, 30, 62, 0.0784313725);
    }
}

.keywords-range {
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgb(213, 213, 213);
    background-color: white;
    width: 100%;
    padding: 10px 50px;
    display: flex;
    column-gap: 30px;   
    button, ul, li {
        height: 100%;
    }
    .count-form {
        #demo-simple-select {
            padding: 5px 30px 5px 8px;
        }
    }
}   
