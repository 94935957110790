@import '../../style/variables.scss';

.input-link--wrapper {
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // margin-left: 100px; 
    .input-link--container {
        min-width: 40%;
        display: flex;
        flex-direction: column;
        max-width: 700px;
        width: 100%;
        align-items: center;
        text-align: center;
        .input__page--text {
            .input__page--title {
                font-weight: 700;
                font-size: 32px;
                line-height: 42px;
                margin: 5px 10px;
                @media screen and (max-width: 500px) {
                    font-size: 28px;
                }
            }
            .input__page--subtitle {
                color: #596679;
                font-size: 22px;
                margin: 0 10px;
                margin-bottom: 35px;
                @media screen and (max-width: 500px) {
                    font-size: 18px;
                    margin-bottom: 20px;
                }
            }
        }
        .form__container {
            display: flex;
            row-gap: 10px;
            justify-content: center;
            width: 100%;
            height: auto;
            box-sizing: border-box;
            @media screen and (max-width: 500px) {
                flex-wrap: wrap;
                column-gap: none;
            }
            .input__page--button {
                font-weight: 500;
                width: 150px;
                border: none;
                border-radius: 0 6px 6px 0;
                padding: 5px;
                @media screen and (max-width: 500px) {
                    border-radius: 6px;
                    height: 40px;
                    min-height: 0;
                    font-size: 16px;
                }   
            }
            .input-link--form {
                height: 50px;
                border-radius: 6px 0 0 6px;
                margin: 0;
                &:focus {
                    box-shadow: none;
                }
                @media screen and (max-width: 500px) {
                    border-radius: 6px;
                    height: 40px;
                    min-height: 0;
                    font-size: 16px;
                }
            }
        }
    }
}

  


@media screen and (max-width: 500px) {

    .input-link--form {
        margin: 0 10px;
    }
    .input__page--spinner {
        width: 20px;
        height: 20px;
    }
}