@import '../../style/variables.scss';

.mini-card--container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    min-height: 72px;
    box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
    border-radius: 6px;
    padding: 10px 15px;
    border-left: 6px solid $main-color;
    background-color: $card-background-color;
    .mini-card--text {
        * {
            color: $main-text-color;
            margin: 0;
        }
        .mini-card--title {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
        }
        .mini-card--value {
            font-size: 24px;
            font-weight: 500;
            color: $main-color;
        }
        .mini-card--error {
            font-size: 22px;
            font-weight: 500;
            opacity: 0.8;
        }
    }
    .mini-card--icon {
        margin-left: auto;
        position: relative;
        &:hover .info__icon--msg {
            display: block;
        }
        img {
            opacity: 0.7;
        }
        .info__icon--msg {
            line-height: 20px !important;
            opacity: 0.9;
            font-size: 13px;
            z-index: 3;
            border-radius: 5px;
            width: 250px;
            font-weight: 500 !important;
            padding: 10px;
            color: white !important;
            position: absolute;
            display: none;
            background-color: rgb(0, 86, 167);
            top: 30px;
            left: -200px;
            animation: scaleUp 0.1s;
        }
    }
}

