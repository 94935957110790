$app-background-color: rgb(244,248,252);

$main-color: #0d6efd;
$main-text-color: rgb(72, 72, 72);
$white-color: white;

$card-background-color: #ffffff; 
$change-device-btn-bfr: #ff990037;
$error-card: rgba(255, 0, 0, 0.718);
$issues-arrow-container: rgb(252, 252, 252);
$card-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
$card-border-radius: 6px;