@import '../../style/variables.scss';

.report__analysis {
    .report__analysis--radar{
        font-size: 10px;
        .report__analysis--title {
            font-weight: 700;
            font-size: 20px;
        }
        .recharts-responsive-container {
            height: 200px  !important;
            width: 100% !important;
        }
    }
    @media screen and (max-width: 1200px) {
        display: none;
    }
}

