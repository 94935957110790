.skeleton {
    display: inline-block;
    width: 60%;
    height: 360px !important;
    background: #f0f0f0;
    animation: loading 1.5s infinite;
    height: 20px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .skeleton-block {
    margin: 5px 0;
    background: #e0e0e0;
    border-radius: 4px;
  }
  
  @keyframes loading {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  