@import '../../style/variables.scss';

.report__preview {
    flex: 1 1 100% !important;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
    .report__data--container {
        .report__preview--title {
            font-weight: 700;
            font-size: 20px;
            margin: 0;
        }
        .report__review--img {
            position: relative;
            max-width: 400px;
            .mobile_screen {
                right: -10px;
                top: 60px;
                max-width: 180px;
                max-height: 220px;
                position: absolute;
                @media screen and (max-width: 540px) {
                    display: none;
                }
            }
        }
    }
    
    
    
    .skeleton-item1 {
        width: 60%;
        margin: 0;
        height: 10px;
    }
    .skeleton-item2 {
        width: 40%;
        margin: 0;
        height: 8px;
    }
    .skeleton--blck {
        align-items: center;
        row-gap: 10px;
    }

    @media screen and (max-width: 960px) {
        max-width: 100% !important; 
    }

}