@import '../../style/variables.scss';

.report-container {
    .report__row {
        flex-wrap: wrap;
    }
}


.report__row {
    max-width: 100%;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;
    .report__web-icon {
        position: relative;
        min-width: 60px;
        min-height: 60px;
        box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
        border-radius: 8.57143px;
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        padding: 15px;
        margin: 0 20px 0 0;
        background: $card-background-color;
        img {
            width: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            @media screen and (max-width: 680px) {
                width: 40px;
                height: 40px;
                min-width: 0;
                min-height: 0;
            }
        }
        @media screen and (max-width: 960px) {
            margin-right: 10px;
        }
        @media screen and (max-width: 680px){
            width: 40px;
            height: 40px;
            min-width: 0;
            min-height: 0;
        }
        @media screen and (max-width: 560px) {
            display: none;
        }
    }
    .report__web-text {
        min-width: 150px;
        word-break: break-all;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-right: 20px;
        .report__web--title {
            font-size: 26px;
            font-style: normal;
            display: block;
            line-height: 39px;
            color: #130D41;
            letter-spacing: 0;
            box-sizing: border-box;
            font-weight: 900;
            @media screen and (max-width: 960px) {
                font-size: 18px;
                line-height: 18px;
            }
            @media screen and (max-width: 680px) {
                font-size: 22px;
                line-height: 22px;
            }
            @media screen and (max-width: 560px) {
                font-style: 16px;
            }
        }
        .report__web--link {
            max-width: 850px;
            color: #596679;
            font-weight: 400;
            letter-spacing: 0.010em;
            line-height: 20px;
            @media screen and (max-width: 960px) {
                font-size: 13px;
            }
            @media screen and (max-width: 680px) {
                font-style: 14px;
            }
            @media screen and (max-width: 560px) {
                font-style: 11px;
            }
        }
    }
    .download-button {
        text-wrap: nowrap;
        padding: 20px;
        background-color: rgba(58, 181, 38, 1);
        font-size: 18px;
        position: relative;
        border: none;
        height: 36px;
        align-items: center;
        max-height: 43px;
        line-height: normal;
        text-transform: none;
        font-weight: 500;
        display: flex;
        justify-content: center;
        column-gap: 10px;
        border-radius: 30px;
        color: $white-color;
        max-width: 100%;
        box-sizing: border-box;
        box-shadow: 0 4px 10px 0 rgba(14,30,62,0.08);
        transition: all .15s ease;
        &:hover {
            cursor: pointer;
            box-shadow: 0px 4px 6px rgba(14,30,62,0.2);
            transition: all .15s ease;
            @media screen and (max-width: 920px) {
                transform: none;
                box-shadow: none;
            }
        }
        &:active {
            transform: translateY(1px);
            @media screen and (max-width: 920px) {
                transform: none;
            }
        }
        &.disabled-button {
            opacity: 0.7;
            background-color: gray;
            &:hover {
                cursor: default !important;
                transform: none;
                box-shadow: 0 4px 10px 0 rgba(14,30,62,0.08);
            }
        }
        img {
            color: $white-color;
            max-width: 24px;
        }
        .text-dwnld, .text-traf {
            @media screen and (max-width: 720px) {
                font-size: 13px;
            }
        }
        .text-dwnld {
            @media screen and (max-width: 960px) {
                display: none;
            }
            @media screen and (max-width: 680px) {
                display: block;
            }
            @media screen and (max-width: 560px) {
                display: none;
            }
        }
        @media screen and (max-width: 720px) {
            padding: 5px 10px !important;
        }
    }
    @media screen and (max-width: 720px) {
        display: flex;
    }
}


.skeleton--img {
    height: 50%;
}



