@import '../../style/variables.scss';

.fixed__container {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 100;
    box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
    .header__wrapper {
        box-sizing: border-box;
        z-index: 2;
        padding: 15px 70px;
        background-color: $card-background-color;
        @media screen and (max-width: 680px) {
            padding: 15px 20px;
        }
        .c-container {
            justify-content: right;
            display: flex;
            max-height: 40px;

            .header__title {
                text-align: center;
                margin-right: auto;
                font-weight: 700;
                text-align: left;
                text-wrap: wrap;
                color: $main-color;
                font-size: 18px;
                .title {
                    margin-left: 10px;
                    color: #57ada1;
                }
                &:hover {
                    cursor: pointer;
                }
                @media screen and (max-width: 560px) {
                    line-height: 20px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }
                .title {
                    @media screen and (max-width: 960px) {
                        font-size: 14px;
                    }
                    @media screen and (max-width: 770px) {
                        display: none;
                    }
                }
                .header__icon {
                    width: 50px;
                    @media screen and (max-width: 960px) {
                        width: 30px;
                    }
                    @media screen and (max-width: 770px) {
                        width: 40px;
                    }
                }
            }
            @media screen and (max-width: 460px) {
                max-height: 50px;
            }
            .input__component--form {
                margin-left: 10px;
                border-radius: 6px 0 0 6px;
            }
            
            .input__component--button { 
                width: 120px;
                max-height: 31px;
                border-radius: 0 6px 6px 0;
                margin-right: 10px;
            }
            .language__icon {
                text-transform: uppercase;
                height: 100%;
                width: 25px;
                color: rgba(0, 0, 0, 0.726);
                font-size: 20px;
                font-weight: bold;
                padding: 0;
                margin: 0;
                line-height: 20px;
            }
            .dropdown-toggle {
                border: none;
                padding: 0;
                &::after {
                    display: none;
                }
            }
            .dropdown-menu {
                min-width: 10px;
            }
        }
    }
    .navbar {
        padding: 0;
        border-top: 2px solid rgba(128, 128, 128, 0.13);
        background-color: $card-background-color;
        display: flex;
        justify-content: center;
        align-items: center;
        .navbar__container {
            padding: 0 70px;
            max-width: 2000px;
            width: 100%;
            @media screen and (max-width: 680px) {
                padding: 0 20px;
            }
            .navbar__links {
                position: relative;
                display: flex;
                justify-content: center;
                .page__link {
                    text-align: center;
                    min-width: 100px;
                    font-size: 16px;
                    color: $main-text-color;
                    padding: 5px 10px;
                    &:hover {
                        cursor: pointer;
                        background-color: #e7f1ff7e;
                        @media screen and (max-width: 860px) {
                            background-color: white;
                            cursor: default;
                        }
                    }
                    &.active-page {
                        position: relative;
                        background-color: #d2e3fc80;
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 1px;
                            background-color: $main-color;
                            left: 0;
                            bottom: 0;
                            @media screen and (max-width: 860px) {
                                height: 3px;
                            }
                        }
                        @media screen and (max-width: 860px) {
                            background-color: $card-background-color;
                        }
                    }

                }
            }
        }
    }
}

.error__input--text {
    margin-top: 5px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    color: red;
    margin-left: 5px;
    @media screen and (max-width: 460px) {
        font-size: 11px;
    }
}

.copy-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    right: 0;
    background-color: transparent;
    color: $main-text-color;
    box-shadow: none;
    border-radius: 5px;
    text-align: center;
    border: none;
    line-height: 14px;   
    font-size: 14px;
    *{
        margin: 0;
    }
    img {
        max-width: 18px;
        margin-right: 5px;
    }
    @media screen and (max-width: 1200px) {
        margin-left: auto;
        position: relative;
        transform: none; 
    }
    @media screen and (max-width: 500px) {
        span {
            display: none;
        }
    }
}