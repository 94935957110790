

@import './variables.scss';

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    overflow-clip-margin: content-box;
    overflow: clip;
    border-style: none;
}

.App {
    background-color: $app-background-color;
}


