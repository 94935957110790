
.input-mail--background {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 120;
    background-color: black;
    opacity: 0.3;
}

.input-mail--wrapper {

    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    
    position: relative;
    padding: 20px 30px;
    position: fixed;
    width: 500px;
    z-index: 121;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    box-shadow: 0px 6px 30px rgba(14, 30, 62, 0.08);
    border-radius: 6px;
    opacity: 0;
    transition: opacity .5s ease;
    text-align: center;
    @media screen and (max-width: 650px) {
        font-size: 16px;
        line-height: 18px;
    }
    @media screen and (max-width: 500px) {
        width: 250px;
    }
    
    form {
        width: 100%;
    }
    .input-form {
        display: flex;
        justify-content: center;
        row-gap: 10px;
        @media screen and (max-width: 500px) {
            flex-wrap: wrap;
        }
        input {
            &:focus {
                box-shadow: none;
            }
            border-radius: 6px 0 0 6px;
            @media screen and (max-width: 500px) {
                border-radius: 6px;
            }
        }
        .mb-3 {
            margin: 0 !important;
        }
        button {
            min-width: 90px;
            border-radius: 0 6px 6px 0;
            @media screen and (max-width: 500px) {
                border-radius: 6px;
            }
        }
        .form-label {
            margin-bottom: 15px;
        }
    }
    .invalid-mail--message {
        font-size: 13px;
        color: red;
        p { 
            margin: 0;
        }
    }
}

.active {
    opacity: 1;
}

